<template>
  <div class="proList">
    <List :numberRow="numberRow" :gutter="gutter">
      <div class="pro_item" v-for="(itemData, index) in lists" :key="index" slot="Item" @click="goDetails(itemData.id)">
        <div class="product_image_box">
          <img :src="itemData.images_view[0]" alt="">
        </div>
        <div class="pro_info_box">
          <div class="product_name">{{ itemData.name }}</div>
          <div class="product_pirce" v-if="itemData.price_type == 2">面议</div>
          <div class="product_pirce" v-else>{{ itemData.price }}</div>
        </div>
      </div>
    </List>
  </div>
</template>

<script>
import List from '../List.vue'
export default {
  name: 'proList',
  components: { List },
  props: {
    numberRow: {
      type: Number,
      default: 4
    },
    gutter: {
      type: Number,
      default: 20
    },
    lists: {
      type: Array,
      default: () => ([])
    },
    //list列表 查看   op 我的分包，有底部按钮，可操作
    itemType: {
      type: String,
      default: 'list'
    },
  },
  data () {
    return {};
  },
  methods: {
    goDetails (id) {
      this.$router.push({
        path: '/proSupply/details',
        query: {
          id
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.proList {
  .pro_item {
    border-radius: 8px 8px 8px 8px;
    width: 225px;
    box-shadow: 0px 0px 5px 0px #e4e9ff;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 0px 12px 0px #c5c8d5;
    }
    .product_image_box {
      width: 225px;
      height: 225px;
      border-radius: 8px 8px 8px 8px;
      overflow: hidden;
      opacity: 1;
    }
    .pro_info_box {
      background: #ffffff;
      padding: 13px 20px;
      .product_name {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 10px;
      }
      .product_pirce {
        font-size: 14px;
        font-weight: 400;
        color: #ff5100;
      }
    }
  }
}
</style>
